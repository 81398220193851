.dropdown {
    overflow: hidden !important;
    position: relative;
    width: 100%;
}

.dropdown__trigger svg {
    transform: rotate(-90deg);
    font-size: 30px !important;
    transition: all .3s ease;
}

.svgrotate {
    transform: rotate(0deg) !important;
}

.dropdown__trigger span {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown__trigger {
    font-family: 'Avenir';
    margin-bottom: 5px;
    display: flex;
    outline: none;
    border: none !important;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    padding: 3px 13px;
    font-weight: 550;
    font-size: 10px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);

    transition: all .5s ease;
    z-index: 400;

}

.dropdown__options {
    font-family: 'Avenir';
    max-height: 0px;
    overflow: hidden;
    width: 100%;
    list-style: none;
    background-color: #fff;
    border-bottom: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    margin-bottom: 7px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.DropDownInner {
    padding: 10px 16px;
}

.dropdown__options__show {
   
    max-height: 100% !important;

}

.dropdown__option svg {
    font-family: 'Avenir';
    font-size: 20px !important;
}

.dropdown__option {
    font-family: 'Avenir';
    border-bottom: 1px solid black !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    /* position: absolute; */
    font-size: 10px !important;
    list-style: none;
    margin-top: 6px;
    border: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
    padding-bottom: 4px;


}

.dropdown__option li span {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;



}

.dropdown__trigger_button {
    font-family: 'Avenir';
    display: flex;
    outline: none;
    border: none !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 800;
    font-size: 15px;
    padding: 3px 13px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    position: relative;
    transition: all .5s ease;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

}