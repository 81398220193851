.PageBuilder {
    display: flex;
    flex-direction: column;
}

.PageHeader {
    height: 30%;
    position: sticky;
    top: 0;
    z-index: 18000;
}

.pageSide {

    margin-top: 50px;
    display: flex;
    height: 70%;
    flex: 1;
}

.canvas {
    max-height: 100vh;
    /* overflow-y: scroll ; */
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
}

.flex-1 {
    flex: 1 1 0%;
}

.PageBuilderBody {
    width: 100% !important;
    justify-content: center;
}