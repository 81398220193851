.summaryModel {
    z-index: 1100;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    transform: scale(1) !important;
    opacity: 1 !important;
}

.internalBox {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 95%;
    overflow-y: scroll;
}

.ChoosePageImages {
    
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.chooseButton{
  opacity: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content:center;
    height: 100%;
    width: 100%;
    position: absolute;
    transition: all .3s ease;
}
.chooseButton:hover{
    opacity: 1 !important;
}
.chooseButton button{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(256, 256, 256, 0.5);
    border-radius: 5px;
    padding: 0 10px;
    transition: all .3s ease;
}
.ChoosePageImages img {
    width: 100px;
    border-radius: 5px;
}
.chooseButton button:hover{
    background-color: rgba(256, 256, 256, 0.7) !important;
}

.summaryBody {
    width: 60%;
    height: 400px;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.modelHead {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}

.inputBody {}

.inputField {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputField p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;

}

.inputField input {
    padding-left: 10px !important;
    outline: none;
    border: none;
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
}

.inputField2 {
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputField2 p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;

}

.inputField2 input {
    padding-left: 10px !important;
    outline: none;
    border: none;
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
}

.closeButtons {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
}