.HandleYes {
    background-image: url("/public/Yes.png");
    background-size: 100%;
    background-repeat: no-repeat;
    /* border:1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.HandleNO {
    background-image: url("/public/NO.png");
    background-size: 100%;
    background-repeat: no-repeat;
    /* border:1px solid black; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.rate__box {
    width: 100%;
    padding: 2px 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;


}

.rate__box p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 5px;
    line-height: 5px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.1em;

    color: #1B2948;
}



.rete__Container {
    padding: 0px 1px;
    top: -18px;
    left: -30px;
    position: absolute;
    margin-bottom: 10px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 573;
    background: #FFFFFF;
    border: 1.5px solid #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.target {
    left: 0;
}
.target__point {
    z-index: 2;
    pointer-events: none !important;
    height: 100% !important;
    width:100% !important;
    left: 0% !important;
    top: 49% !important;
    opacity: 0;
    border-radius: 10px !important;
}
.warRoomSideHead {
 cursor: pointer;
    width: 100%;
    height: 31px;
    color: white;
    background: #1B2948;
    border-radius: 10px;
    /* margin-top: 30px; */
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.warRoomSideHead p {
    font-size: 15px;
    font-weight: bold;
}

.warRoomSideHead img {
    font-weight: bold !important;
    font-size: 30px !important;
    filter: invert(59%) sepia(184%) saturate(0%) hue-rotate(7deg) brightness(250%) contrast(209%) !important
}

.trafficContainer {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 10px;
}

.traffics {
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1B2948;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 51px;
    height: 51px;
}

.icon-container svg {
    color: red;
}

.traffics p {
    margin-top: 4px;
    font-size: 8px;
    color: white;
    text-align: center;
}

.traffics img {
    height: 18px;
    width: 18px;
}

.yesrate {
    top: 68%;
    position: absolute !important;
    font-size: 10px;
}

.norate {
    position: absolute;
    bottom: -22%;
    font-size: 10px;
    left: 60%;
}