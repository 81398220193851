.summaryModel {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    transform: scale(0);
    opacity: 0;
}
.summaryModelWar {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    transform: scale(1);
    opacity: 1;
}

.summaryBody {
    width: 428px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.modelHead {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}


.inputField{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inputField textarea{
    font-family: 'Avenir';
    resize: none;
    padding-left: 10px !important;
    padding-top: 10px !important;
    padding-right: 1px !important;
    outline: none;
    border: none;
    width: 191px;
    height: 90px;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 15px;
}
.inputField p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;

}
select{
    font-size: 15px !important;
}
.inputField input {
    font-family: 'Avenir';
    padding-left: 10px !important;
    outline: none;
    border: none;
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
    font-size: 10px;
}
.inputField2{
    /* margin-top: 10px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inputField2 p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;

}

.inputField2 input {
    padding-left: 10px !important;
    outline: none;
    border: none;
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
}
.closeButtons{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 20px;
}