.traficmodelContainer {
    z-index: 900;
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transform: scale(0);
    opacity: 0;
    transition: all .3s ease;
}
.showTrafficModel{
    transform: scale(1) !important;
    opacity: 1 !important;
}
.trafficBody {
    padding: 20px;
    width: 603px;
    height: 270px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

}

.trafficBodyHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.trafficBodyHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.trafficBodyHead svg {
    cursor: pointer !important;
    font-weight: 800 !important;
    font-size: 15px !important;
}

.inputFields {
    gap: 20px;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputFields .inputField {
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 50%;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
    /* font-size: 20px; */
    outline: none;
    border: none;
}

.clickable {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    /* padding-left: 10px; */
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.clickable svg {
    font-size: 25px !important;
}

.clickable2 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    /* padding-left: 10px; */
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.clickable2 svg {
    font-size: 25px !important;
}

.clickable3 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    /* padding-left: 10px; */
    width: 191px;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor: pointer;
}

.clickable3 svg {
    font-size: 25px !important;
}

.inputFields p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 11px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.cancelSaveButton {
    margin-top: 10px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.cancel {
    cursor: pointer;
    outline: none;
    border: none;
    width: 82px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;
    padding: 5px !important;
}

.save {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: white;
    cursor: pointer;
    outline: none;
    border: none;
    width: 82px;
    height: 25px;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 5px !important;
}












/* drops down */

.dropDown {
    z-index: 200;
    transition: all .5s ease;
    background-color: #FFFFFF;
    top: 25px;
    position: absolute;
    overflow: hidden;
    max-height: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.dropDown p {
    margin-top: 5px;
    width: 100%;
    padding: 10px;
    background-color: whitesmoke;
}

.dropDown p:hover {
    background-color: white;
}

.shwodropDown {
    max-height: 200px !important;
}
.noBorder {
    border: none!important;
  }