.summaryModel {
    z-index: 900;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    z-index: 900;
    transform: scale(1);
    opacity: 1;
}
.summaryBody::-webkit-scrollbar{
    display: none;
}
.summaryBody {
    overflow-y: auto;
    max-height: 600px;
    width: 584px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.modelHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}
.contentBody{
    height: 24px;
    display: flex;
    justify-content: center;
}
.addAssets{
   
    width: 50%;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    color: #1B2948;
    height: 35px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: center;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
}
.addAssets:hover{
    background-color: #eee;
}
.inputField{
    margin-top: 10px;
    /* display: flex; */
    
}
.inputField2{
    margin-top: 10px;
}
.upButton{
    display: flex;
    align-items: center;
    gap: 5px;
}
.UploadButton {
    position: relative;
    width: 120px;
    height: 35px;
    
}
.UploadButton button{
    border-radius: 10px;
    color: white;
    background-color: #1B2948;
    cursor: pointer;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
}
.UploadButton input {
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}
.inputField p {
    margin-bottom: 8px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 11px;
    letter-spacing: 0.1em;
    color: #1B2948;

}

.inputField input {
    padding-left: 10px !important;
    outline: none;
    border: none;
    width: 100%;
    height: 24px;
    background: #FFFFFF;
    border-radius: 5px;
}