.Funnels {
    justify-content: space-between;
    width: 100%;
    display: flex;
    background: rgba(94, 108, 184, 0.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px 0px 0px 0px;

}

.prodHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prodHead h1 {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #000000;
}

.RightSide {
    flex: 1;
}

.leftSide {
    flex: .3;
}

.FunnelsHead {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #000000;
}

.productDescription {
    margin-top: 20px;
    /* background: rgba(94, 108, 184, 0.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25); */
    /* border-radius: 50px 0px 0px 0px; */
    border-radius: 10px;
    padding: 0px 60px;
}

.productPrice {
    background: #FFFFFF;
    border-radius: 10px;
    margin-top: 20px;
    border-radius: 10px;
    padding: 14px 60px;
}

.purchaseconatiner {
    padding-bottom: 20px;
}

.purchaseconatiner p {
    margin-left: 10px;
    margin-top: 24px;
    margin-bottom: 10px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;

    color: #000000;
}

.EditProductInputField p {
    margin-left: 10px;
    margin-top: 24px;
    margin-bottom: 10px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;

    color: #000000;
}

.RightSide p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;

    color: #000000;
}

.EditProductInputField input {
    margin-top: 5px;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
}

.ImageUpload .uploadContainer {
    position: relative;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    border-radius: 10px;
    width: 100%;
    height: 113px;
    border: 1px dashed #1B2948;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;

}

.description textarea {
    height: 113px !important;
    margin-top: 5px;
    padding-left: 10px;
    padding-top: 10px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
    resize: none;
}

.ImageUpload p {
    margin-bottom: 10px;
    margin-top: 10px;
    margin-left: 10px;
}

.EditProductPurchase {
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    background: #FFFFFF;
    border-radius: 10px;
}
.EditProductPurchase p {
    margin-top: unset !important;
    margin-left: unset !important;
    margin-bottom: unset !important;
}
.EditProductPurchase svg {
    color: #8CC23E !important;
    font-weight: bold;
    font-size: 20px !important;
    cursor: pointer;
}

.ImageUpload .uploadContainer {
    height: 113px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ImageUpload .uploadContainer .image {
    position: relative;
    height: 100%;
    width: 100%;



}

.iconsAndText {
    display: flex;
    justify-content: center;
    gap: 30px;
    top: 30%;
    position: absolute;
    left: 40%;
}

.iconsAndText p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;

    color: #000000;
}

.ImageUpload .uploadContainer .image input {
    opacity: 0;
    cursor: pointer;
    z-index: 100;
    position: absolute;
    height: 100%;
    width: 100%;
}

.ImageUpload .uploadContainer .image svg {
    height: 30px !important;
    width: 30px !important;
}

.uploadContainer img {
    width: 100px;
    height: 80px;
}

.imageChange {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    height: 20px;
    width: 100px;
    position: absolute;
    left: 45.8%;
    bottom: 2px;
}

.imageChange button {
    left: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    top: 0;
    position: absolute;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.2em;
    outline: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

.imageChange input {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;
    opacity: 0;
    z-index: 200;
    position: absolute;
}

.buttons {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Update {
    width: 150px;
    height: 30px;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: 0.2em;
    outline: none;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
}

.discard {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    color: #1B2948;
    width: 100px;
    height: 30px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: center;
    outline: none;
    border: none;
    cursor: pointer;
}

.productHeadBUtton p {
    margin-left: 10px;
    margin-top: 24px;
    margin-bottom: 10px;
    font-family: 'Avenir';
}

.productHeadBUtton {
    margin-bottom: 10px;
    justify-content: space-between;
    display: flex;
    align-items: center;
}

.priceContainer {
    align-items: center;
    display: grid;
    grid-template-columns: 17% 25% auto auto;
    grid-column-gap: 88px;
}

.priceContainer2 {
    position: relative;
    padding: 10px;
    background-color: white;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 20% 22% auto auto auto;
    grid-column-gap: 88px;
    transition: all .3s ease;
}

.priceContainer2 p {
    display: flex;
    align-items: center;
    gap: 5px;
}

.priceContainer2 p span {
    height: 7px !important;
    width: 7px !important;
    background: #8CC23E;
    border-radius: 50%;
}

.priceContainer2:hover {
    background-color: #eee;
}

.visibleButton {
    display: flex;
    align-items: center;
    gap: 5px;

}

.priceEdit {
    justify-content: space-between;
    width: 100px;
    display: flex;
    gap: 5px;
}

.priceEdit svg {
    cursor: pointer;
    position: absolute;
    opacity: 0 !important;
    left: 10%;
    transition: all .3s ease;
}

.priceContainer2:hover>.priceEdit svg {
    opacity: 1 !important;
}