.Funnels {
    background: rgba(94, 108, 184, 0.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50px 0px 0px 0px;
    padding: 20px 60px;
    height: 100%;
  }
  
  .FunnelsHead {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1em;
    color: #000000;
  }
  
  .HeadContainer {
    margin-top: 8px;
    width: 100%;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: space-between;
  }
  
  .FunnelsSearch {
    flex: 0.5;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .FunnelsSearchD,
  .FunnelsSearchD1 {
    flex: 0.25;
    margin-bottom: 21px;
  }
  .FunnelsSearchD p,
  .FunnelsSearchD1 p {
    font-family: Avenir;
    font-size: 12px;
    padding-left: 10px;
  }
  
  .FunnelsSearchDrop {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 6px;
  }
  .FunnelsSearchDrop1 {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border-radius: 10px;
    padding: 6px;
  }
  
  .FunnelsSearch input {
    width: 100%;
    padding-left: 10px;
    outline: none;
    border: none;
    font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #000000;
  }
  .inputField {
    width: 100%;
  }
  .FunnelsSearch svg {
    font-size: 20px !important;
  }
  
  /* drops down */
  
  .dropdown {
    overflow: hidden !important;
    position: relative;
    width: 100%;
  }
  
  .dropdown__trigger svg {
    font-size: 30px !important;
    transition: all 0.5s ease;
  }
  
  .svgrotate {
    transform: rotateX(180deg);
  }
  
  .dropdown__trigger {
    display: flex;
    outline: none;
    border: none !important;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 550;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    position: relative;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease;
    background: #ffffff;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  
  .dropdown__options {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    width: 100%;
    list-style: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
    transition: all 0.5s ease !important;
  }
  
  .dropdown__options__show {
    transition: all 0.5s ease;
    max-height: fit-content !important;
  }
  
  .dropdown__option svg {
    font-size: 20px !important;
  }
  
  .dropdown__option {
    border-bottom: 1px solid black !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    /* position: absolute; */
    font-size: 18px !important;
    list-style: none;
    padding: 10px;
    font-size: 20px !important;
    border: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown__option:hover {
    background-color: #f5f5f5;
  }
  
  .headButton button {
    cursor: pointer;
    background: #1b2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    width: 220px;
    padding: 10px;
  }
  
  .cardContainer {
    /* margin-top: 22px; */
    /* display: grid; */
    /* grid-template-columns: auto auto auto; */
    /* grid-column-gap: 45px; */
    /* grid-row-gap: 22px; */
  }
  
  .tableRow {
    margin-top: 10px;
    padding: 0 25px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: start;
    grid-template-columns: 25% auto auto auto;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
  
  .tableRow p {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #000000;
    margin-right: 100px;
  }
  
  .tableRow svg {
    cursor: pointer !important;
  }
  
  .imageAndName {
    display: flex;
    align-items: center;
    gap: 23px;
  }
  
  .imageAndName img {
    height: 42px;
    width: 42px;
    border-radius: 10px;
  }
  
  .tableHead {
    margin-top: 25px;
    width: 100%;
    padding: 0 25px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: start;
    grid-template-columns: 45.3% 25.3% 6.3% 7.3%;
    border-radius: 10px;
  }
  
  .tableHead p {
    font-family: "Avenir";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #000000;
    margin-right: 100px;
  }
  
  .box,
  .box2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    margin: 0 10px 20px 10px;
    background-color: #fff;
    flex: 0.25;
    height: 101px;
    border-radius: 10px;
    padding: 10px 15px;
    display: grid;
    align-items: stretch;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .box .content:first-child {
    margin-left: 0px !important;
  }
  .box .contect:last-child {
    margin-right: 0px !important;
  }
  
  .one {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .two {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .one p:first-child,
  .two p:first-child {
    font-size: large;
    font-weight: bold;
  }
  .one p:last-child,
  .two p:last-child {
    font-size: 11px;
    font-weight: lighter;
  }
  .graph {
    flex: 1;
    height: 402px;
    border-radius: 10px;
    background-color: #fff;
    padding-right: 15px;
    display: block;
    padding: 15px 20px 15px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  .line {
    width: 759px;
    height: 207px;
    display: flex;
    justify-content: center;
    margin: 45px 73px;
  }
  .heading {
    font-family: Avenir;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.1em;
    text-align: left;
    width: 400px;
    height: 38px;
    display: flex;
  }
  .heading p{
    padding-top: 10px;
    margin-right: 18px;
  }
  .calendar {
    width: 343px;
    /* height: 402px; */
    border-radius: 10px;
    background-color: #fff;
    display: block;
    padding: 15px 20px 15px 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  .view {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15px;
  }
  .view h5,
  .view h6 {
    font-family: Avenir;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-align: left;
  }
  