.summaryModel {
    z-index: 190000 !important;
    position: fixed;
    width: 100%;
    height: 60%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    z-index: 900;
    transform: scale(1);
    opacity: 1;
}

.summaryBody {
    max-height: 500px;
    width: 684px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.listItems {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.listItemsleft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.listItems input[type=checkbox] {
    cursor: pointer;
    accent-color: #1B2948;
}

.modelHead {
    display: flex;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}

.settingBody {
    background-color: #FFFFFF;
    padding: 10px;
}

.fromWhere {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 30px;
}

.imageLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-height: 400px;
    overflow-y: hidden;
    margin-top: 10px;
    padding: 20px 0;
    margin-bottom: 100px;

}

.imageLists input {
    padding-left: 10px;
    width: 50%;
    outline: none;
    border-radius: 10px;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.unsplashImageDiv {
    position: relative;
    width: 200px;
    height: 200px;
    margin-top: 10px;
}

.unsplashImageDiv:hover .copylinkIcon {
    opacity: 1 !important;
    background-color: rgba(0, 0,S 0, 0.25) !important;
}

.copylinkIcon {
  
    cursor: pointer;
    top: 0;
    display: flex;
    align-items: center;
    justify-content:center;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity:0;
    transition:  opacity .3s ease;

}

.images {
    padding-top: 10px;
    max-height: 400px;
    overflow-y: scroll;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.images img {
    cursor: pointer;
    height: 200px;
    width: 250px;
}
.chooseButton{
    opacity: .8 !important;
}