.sidebar {
    min-height: 100vh;

}

.routes {
    cursor: pointer;
    margin-top: 15px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 196px;
    height: 44px;
    background: rgba(94, 108, 184, 0.1);
    border-radius: 0px 10px 10px 0px;
}

.activePath {
    background: #1B2948 !important;
    color: white !important;
}

.activePath img {
    filter: invert(59%) sepia(184%) saturate(0%) hue-rotate(7deg) brightness(250%) contrast(209%) !important
}
.activePath svg {
    filter: invert(59%) sepia(184%) saturate(0%) hue-rotate(7deg) brightness(250%) contrast(209%) !important
}