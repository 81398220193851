.addNewDrawer {
    z-index: 150000 !important;
    overflow: hidden;
    /* width: 20%; */
    position: fixed;
    left: 0;
    transform: translate(-300%);
    transition: all 1s ease;
    background: #FFFFFF;
    /* Drop Shadow */
    padding: 20px 10px;
    max-height: 75%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    height: 100%;


}

.unsplashImageDiv {
    position: relative;
    width: 100%;
    height: 200px;
    margin-top: 10px;
}

.unsplashImageDiv:hover .copylinkIcon {
    visibility: visible !important;
}

.copylinkIcon {
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: absolute;
    /* height: 100%;*/
    width: 100%;
    /* background-color: rgba(0, 0,S 0, 0.25); */
    visibility: hidden;

}

.addProdDrawer {

    z-index: 150000 !important;
    overflow: hidden;
    /* width: 20%; */
    position: fixed;
    transform: translate(-200%);
    transition: all 1s ease;
    background: #FFFFFF;
    /* Drop Shadow */
    padding: 10px 10px;
    max-height: 75%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    height: 100%;
}


.menuText {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;
    color: #1B2948;
}

.showAddDrawer {

    transform: translate(7%);
}




/* prod add */

.addElementGrid {
    height: 90%;
    display: flex;
    gap: 5px;
}

.addElementBox2 {
    text-align: left;
}

.addElementBox2 h1,
.addElementBox2 p {
    text-align: left !important;
    cursor: pointer;
    border: 1px solid white;
    transition: all .5s ease;
    padding: 1px 10px;
}


.ptag p {
    width: 250px;

}

.headTags {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.titleTag {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.paraTag {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.addElementBox2 h1:hover,
.addElementBox2 p:hover {
    /* opacity: .7; */
}

.imageTags {
    width: 100%;
   margin-left:5px;
   gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


}

.imageTags input {
    padding-left: 10px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid black;

}

.imageTags img {
    cursor: pointer;
    width: 100%;
    height: 200px;

}

.videoSection {
    padding-bottom: 50px;
}

.videoSection img {
    cursor: pointer;
    width: 100%;
    height: 200px;
    margin-top: 5px;
}

.addElementBox {

   
    padding-left: 10px;
    padding-right: 30px;

    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* overflow-y: scroll !important; */
    overflow-x: hidden;
    flex-direction: column;
}

.addElementBoxOne {
    margin-top: 0px;
    padding-left: 10px;
    padding-right: 30px;
   
    display: flex;
    align-items: center;
    overflow-x: hidden;
    flex-direction: column;
    border-right: 1px solid gray;
}

.paragraph:hover {
    color: #9a9595;
}

.addElementBox2 {
   
    margin-top: 10px;
    height: 508px;
    max-height: 500px;
    padding-left: 10px;
    padding-right: 30px;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    /* overflow-y: scroll !important; */
    overflow-x: hidden;
    flex-direction: column;
}

.box {

    width: 90% !important;
    height: 30px;
    margin-top: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;
    letter-spacing: 0.1em;
    display: flex;
    align-items: center;
    padding-left: 10px;
    position: relative;
    color: #000000;
}

.svgPlus {
    font-size: 20px !important;
    color: #8CC23E !important;
    position: absolute;
    right: -10px;

}

.activeTag {
    border: 1px solid #000000 !important;
    border-radius: 50px;
    background-color: #1b2948df;
    color: white;
}

.ManuBar {
    cursor: pointer;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ManuBar img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.menuOptions ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.menuOptions ul li {
    width: 100px;
    height: 30px;
    background-color: rgba(231, 215, 19, 1);
    border-radius: 30px;
    color: black;
    list-style: none;
    text-align: center;

}

.ManuBarOne {
    cursor: pointer;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(#e66465, #9198e5);
}

.menuOptionOne ul {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

}

.menuOptionOne ul li {
    width: 100px;
    height: 30px;

    border-radius: 30px;
    color: black;
    list-style: none;
    text-align: center;

}

.heading {
    display: flex;
    align-items: flex-end;
    z-index: 10;
    position: sticky;
    top: 0;
    height: 5%;
    background-color: white;
    border-bottom: 1px solid gray;
}