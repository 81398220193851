.summaryModel {
    z-index: 800;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    z-index: 900;
    transform: scale(1);
    opacity: 1;
}

.summaryBody {
    width: 384px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}
.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.listItems {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.listItemsleft {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.listItems input[type=checkbox] {
    cursor: pointer;
    accent-color: #1B2948;
}

.modelHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}
.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}
.settingBody{
    background-color: #FFFFFF;
    padding: 10px;
}