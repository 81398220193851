.ActionHandle{
    left: 0 !important;
    top: 0 !important;
    z-index: 20 !important;
    /* left: 45% !important; */
    height: 100% !important;
    width: 100% !important;
    transform: unset !important;
    pointer-events: none !important;
}
