.Funnels {
  background: rgba(94, 108, 184, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px 0px 0px 0px;
  padding: 20px 60px;
  height:100%

}
.FunnelsCreate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FunnelsHead {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
}
.Button button {
  cursor: pointer;
  background: #1b2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  width: 107px;
  height: 34px;
  text-align: center;
  margin-left: 10px;
  font-family: "Avenir";
  font-style: normal;
}
.Button button:first-child {
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #1b2948;
  width: 107px;
  height: 34px;
  text-align: center;
  margin-left: 10px;
  font-family: "Avenir";
  font-style: normal;
}
.content {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
}
.input {
  display: inline;
  margin-right: 20px;
  margin-top: -22px;
}
.input label {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
}
.input input {
  width: 673px;
  height: 40px;
  border-radius: 10px;
  margin-bottom: 15px;
  padding-left: 10px;
}
.inputone {
  display: flex;
}
.inputone label {
  margin-right: 10px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
}
.inputone input {
  width: 264px;
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
}
.side {
  width: 276px;
  height: 157px;
  border-radius: 10px;
  background-color: #fff;
  padding: 20px 15px;
  display: block;
}
.icon {
  transform: rotate(270deg);
  width: 16px;
  height: 17.13px;
}
.manager {
  display: flex;
}
.manager > h5 {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  width: 123px;
  height: 20px;
  margin-left: 8px;
}
.side p {
  font-family: Avenir;
  font-size: 8px;
  font-weight: 500;
  line-height: 11px;
  letter-spacing: 0.1em;
  text-align: left;
  margin-left: 8px;
}
.bar {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 35px;
}
.icon {
  transform: rotate(0deg);
  width: 102.66px;
  height: 40.66px;
}
.https {
  display: flex;
}
.https p {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  width: 57px;
  height: 38px;
  padding-top: 11px;
}
.https input {
  width: 615px;
}

.payment label {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: center;
  width: 87px;
  height: 38px;
  padding-top: 10px;
}
.payicon {
  width: 90px;
  height: 30px;
  display: flex;
  justify-content: center;
}
.paypal,
.chargebe {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
}
.stripe {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 8px 0px;
}
.paycontent {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 8px 0px;
}
.paycontent > input {
  width: 262px;
  height: 40px;
  border-radius: 10px;
  padding-left: 10px;
}
.paycontent button {
  width: 107px;
  height: 40px;
  border-radius: 10px;
  background-color: #1b2948;
  color: #fff;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: center;
  margin-left: 12px;
}
