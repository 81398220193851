.Funnels {
  background: rgba(94, 108, 184, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px 0px 0px 0px;
  padding: 20px 60px;
  height:100%
}

.FunnelsHead {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
}

.HeadContainer {
  margin-top: 8px;
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-between;
}

.FunnelsSearch {
  width: 476px;
  /* flex: 1; */
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  height: 34px;
}

.FunnelsSearchDrop {
  flex: 0.5;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 6px;
}

.FunnelsSearch input {
  width: 100%;
  padding-left: 10px;
  outline: none;
  border: none;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #000000;
}
.inputField {
  width: 100%;
}
.FunnelsSearch svg {
  font-size: 20px !important;
}

/* drops down */

.dropdown {
  overflow: hidden !important;
  position: relative;
  width: 100%;
}

.dropdown__trigger svg {
  font-size: 30px !important;
  transition: all 0.5s ease;
}

.svgrotate {
  transform: rotateX(180deg);
}

.dropdown__trigger {
  display: flex;
  outline: none;
  border: none !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: 550;
  border: 1px solid #ccc;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  transition: all 0.5s ease;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.dropdown__options {
  position: absolute;
  max-height: 0;
  overflow: hidden;
  width: 100%;
  list-style: none;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  transition: all 0.5s ease !important;
}

.dropdown__options__show {
  transition: all 0.5s ease;
  max-height: fit-content !important;
}

.dropdown__option svg {
  font-size: 20px !important;
}

.dropdown__option {
  border-bottom: 1px solid black !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  /* position: absolute; */
  font-size: 18px !important;
  list-style: none;
  padding: 10px;
  font-size: 20px !important;
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}

.dropdown__option:hover {
  background-color: #f5f5f5;
}

.headButton button {
  cursor: pointer;
  background: #1b2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  width: 220px;
  height: 34px;
  font-family: Avenir;
  font-size: 13px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: center;

  /* padding: 10px; */
}

.cardContainer {
  /* margin-top: 22px; */
  /* display: grid; */
  /* grid-template-columns: auto auto auto; */
  /* grid-column-gap: 45px; */
  /* grid-row-gap: 22px; */
}

.tableRow {
  margin-top: 10px;
  padding: 0 25px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: start;
  grid-template-columns: 25% auto auto auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.tableRow p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  color: #000000;
  margin-right: 100px;
  width: -webkit-fill-available;
  text-align: left;
  text-wrap:nowrap;
  display: flex;
}

.tableRow p span {
  margin-top:2px;
}
.tableRow svg {
  cursor: pointer !important;
}

.imageAndName {
  display: flex;
  align-items: center;
  gap: 23px;
}

.imageAndName img {
  height: 42px;
  width: 42px;
  border-radius: 10px;
}

.tableHead {
  margin-top: 25px;
  width: 100%;
  padding: 0 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: start;
  grid-template-columns: 45.3% 25.3% 6.3% 7.3%;
  border-radius: 10px;
  text-wrap: nowrap;
}

.tableHead p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #000000;
  margin-right: 110px;
  width: -webkit-fill-available;
  text-align: left;
}
