@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url('https://use.typekit.net/mpa1wkh.css'); */
body {
  height: 100vh !important;
}
.MuiBackdrop-root {
  /* display: none !important; */
}
.checkedIcon {
  background: rgb(19, 115, 230);
  border-color: transparent;
  display: "block";
  width: "100%";
  height: "100%";
  border-radius: 100%;
  background: #fff;
}
.checkedIcon::before {
  /* content: ""; */
  display: "block";
  width: "100%";
  height: "100%";
  border-radius: 100%;
  background: #fff;
}
#menu- {
  z-index: 1300000;
}
