.DrawerMain {
    overflow-y: scroll;
    z-index: 170000;
    position: fixed;
    width: 20%;
    transform: translateX(90%);
    max-height: 75%;
    height: 74%;
    right: 0;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .5s ease;

}

.drawerBody {
    position: relative;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: flex;
   
    margin-left: 20px;
    width: 95%;
    height: 100%;

}

.rotatesVG {
    transform: rotate(180deg);
}

.closeDrawer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 12%;
    width: 5%;
    background-color: #1B2948;
    left: -5% !important;
    top: 50% !important;
    position: absolute;
    border-radius: 10px 0px 0px 10px;
}

.openDrawer {

    transform: translateX(0%);
}