

.war_room{

    display: flex;
    flex-direction: column;
}
.dndflow{
    overflow: hidden;
    height: 88vh !important;
    /* flex: 1; */
}
.war_room_head {
    position: relative !important;
    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
    top: 0;
    z-index: 950;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.war_room_head_left {
    margin-left: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.war_room_head_left input {
    outline: none;
    border: none;
    font-weight: bold;
}

.war_room_head svg {
    cursor: pointer;
    height: 20px !important;
    width: 20px !important;
}

.war_room_head_left p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.1em;

    color: #000000;
}

.war_room_head_right {
    margin-right: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.war_room_head_right_button {
    width: 108px;
    height: 30px;
    left: 1030px;
    top: 83px;
    cursor: pointer;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: white;
}

.custom_control {
    font-size: 10px;
    font-family: 'Avenir';
    cursor: pointer;
    width: 108px !important;
    height: 30px;
    color: white;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-flow__controls-button{
    width: 100px !important;
}

.controls_body{
    transition: all .5s ease;
    bottom: 26px;
    gap: 10px;
    display: flex;
    position: fixed;
    align-items: center;
}
.custom_control_icon svg {
    cursor: pointer;
    font-size: 25px !important;
}