/* Modal */
.modal {
  z-index: 1000 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0);
  opacity: 0;
  transition: all .3s ease;
}

input {
  display: flex;
  align-items: center !important;
}

.showModel {
  transform: scale(1);
  opacity: 1;

}

.modal-content {
  width: 500px;
  background: #EFF0F8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.modal-header {

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.modal-tabs {

  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.modal-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
  background: #E0E3F2;
  border-radius: 20px;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
  width: 100px;
  height: 30px;
  text-decoration: none;
  color: black;
}



.top_head {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.modal-tab.active,
.modal-tab:hover {
  color: white;
  background: #1B2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.modal-close-button {

  font-size: 16px;
  cursor: pointer;
  width: 90px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;

}

.modal-close-button2 {
  color: white;

  font-size: 16px;
  cursor: pointer;
  width: 90px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1B2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #ddd;
}

.InputFields {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

}

.InputFields2 {
  margin-top: 10px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  gap: 150px;

}

.customize input {
  padding-left: 10px;
  height: 24px;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  border: none;
}

.customize .filetype {
  cursor: pointer;
  padding-left: 10px;
  padding-top: 0px;
  /* width: 50%; */
  height: 30px;
  background: unset !important;
  border-radius: 5px;
  outline: none;
  border: none;
}

.closeCross svg {
  cursor: pointer;
  font-size: 30px !important;
  color: #1B2948 !important;
}

.InputFields input {
  font-size: 12px;
  padding-left: 10px;
  display: flex;
  align-items: center;
  width: 50%;
  height: 24px;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  border: none;
}

.InputFields textarea {
  resize: none;
  padding-left: 10px;
  padding-top: 10px;
  width: 50%;
  height: 132px;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  border: none;
}

.InputFields2 input {
  padding-left: 10px;
  padding-top: 10px;
  width: 50%;
  height: 24px;
  background: #FFFFFF;
  border-radius: 5px;
  outline: none;
  border: none;
}

.InputFields {
  /* display: flex; */
  gap: 10px;
  position: relative;
}

.InputFields input .InputFields div {
  width: 50% !important;
}

/* toggle button */

.checkbox {
  height: 1.2rem;
  border-radius: 20px;
  border: 2.5px solid #EFF0F8;
  background: #EFF0F8;
  color: #1B2948;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0.5px 1px 0px rgba(19, 19, 19, 0.72);

}

.checkbox1 {
  top: 2px;
  left: 49.5%;
  position: absolute;
  height: 1.2rem;
  border-radius: 20px;
  border: 2.5px solid #EFF0F8;
  background: #EFF0F8;
  color: #1B2948;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0px 0.5px 1px 0px rgba(19, 19, 19, 0.72);

}

.checkbox--on {
  flex-direction: row;
  background: #1B2948;
  border: 2.5px solid #1B2948;
  color: white;
  transition: all 1s ease;
}

.checkbox--on .checkbox__ball {
  box-shadow: 0px 3px 4px 0px rgb(29, 80, 29);
}

.checkbox__ball {
  background: white;
  box-shadow: 0px 3px 4px 0px rgba(50, 50, 50, 0.72);
  width: 20px;
  height: 20px;
  border-radius: 50%;

}

.checkbox__text {
  font-size: 10px;
  margin-left: 5px;
  margin-right: 5px;


}



/* customize */

.customize {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.colors {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.color {
  transition: all 1s ease !important;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  cursor: pointer;
}

.Products_in_war {
  position: relative;
  margin-top: 20px;
}

.allSelectValue {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
}

.slaesNumber {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ce;
}

.innerSelect {
  display: flex;
  gap: 5px;
  align-items: center;
}

.innerSelect svg {
  cursor: pointer !important;
}

.allSelectValueSplit {
  gap: 3px;
  top: 27%;
  left: 0%;
  /* position: absolute; */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.InputPercentage input {
  padding-left: 2px;
  outline: none;
  border: none;
  width: 70%;
  height: 100%;
}

.InputPercentage p {
  font-size: 10px;
}

.InputPercentage {
  box-shadow: 0px 2px 4px -2px #1B2948;
  border: none;
  padding: 2px;
  height: 25px;
  /* border: 1px solid #1B2948; */
  border-radius: 2px;
  display: flex;
  align-items: center;
  gap: 1px;
  width: 35px;
  background-color: white;
}