.TrafficNode {
    position: relative;
    cursor: pointer;
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    width: 185px;
    height: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


}
.TrafficNode svg{
    /* z-index: 0; */
    right: -10px;
    font-size: 20px;
    color: #8CC23E;
    position: absolute;
}
.traffic__Item {
    padding: 0 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;


}

.traffic__Item p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.traffic__Item img {
    height: 30px;
    border-radius:5px;
    width: 30px;
    filter: invert(9%) sepia(4%) saturate(0%) hue-rotate(7deg) brightness(20%) contrast(209%);
}
.traffic__Item svg {
   position: relative !important;
}

.trafficHandle {
    opacity: 0;
    background: transparent;
    background-size: 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0 !important;
    top: 50% !important;
}