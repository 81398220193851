@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Avenir";
  src: url("./fonts/AvenirNextLTPro-Regular.otf");
}

* {
  margin: 0;
  padding: 0;
}

p,
h1,
span,
button,
input,
h1,
h3,
h2,
h4,
a {
  font-family: "Avenir";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.war_room_head {
  /* top: 70px !important; */
  /* z-index: 800; */
  /* position: absolute !important; */
  /* display: flex; */
  /* height: 30%; */
}
.comparison_header {
  box-shadow: 0px 4px 4px 0px #00000040;
  height: 53px;
  background: #1f2948;
  border-radius: 10px;
  margin-top: -18px;
}
.comparison_header p {
  font-family: Avenir;
font-size: 13px;
font-weight: 800;
line-height: 18px;
letter-spacing: 0.1em;
text-align: center;
  padding-top: 25px;
color: #fff;
}
.war_room_container {
  /* height: 100vh; */
}

.react-flow {
  height: 100vh !important;
  /* height: 84vh !important; */
  /* margin-top: 10px; */
}

.trafficsNode {
  margin-top: 13px;
  position: relative;
  cursor: pointer;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  width: 90%;
  height: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.trafficsNode img {
  height: 30px;
  border-radius: 5px;
  width: 30px;
  filter: invert(9%) sepia(4%) saturate(0%) hue-rotate(7deg) brightness(20%)
    contrast(209%);
}

.trafficsNode svg {
  position: relative;
  color: #8cc23e;
  font-size: 20px;
}

.trafficHandlePlus {
  position: absolute !important;
  color: #8cc23e !important;
  font-size: 20px !important;
  right: -10px !important;
}

.trafficNodeContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 30px;
  grid-row-gap: 10px;
}

.react-flow__handle {
  height: 10px !important;
  width: 10px !important;
  /* background: transparent !important; */
}

.react-flow__handle {
}

.react-flow__handle-right {
  right: -7px !important;
  top: 58%;
}

.react-flow__handle-left {
  left: 46% !important;
  top: 100% !important;
}

.box-wrapper {
  display: flex;
  gap: 10px;
  padding: 20px;
}

.react-flow__controls-button {
  border-bottom: unset !important;
}

.react-flow__controls-button svg {
  color: #fff !important;
}

.warRoomSideHead {
  margin-top: 31px;
}

.react-flow__panel.left {
  background: #ffffff;
  justify-content: center;
  display: flex !important;
  left: -10% !important;
  z-index: 1000 !important;
}

.react-flow__panel.bottom {
  bottom: 10% !important;
}

.react-flow__pane {
  cursor: crosshair !important;
}

.box {
  width: 100px;
  height: 100px;
  background: blue;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectNode {
  border: 1px dotted black !important;
}

.box-internal-wrapper_active {
  border: 1px dashed black !important;
}

.box-internal-wrapper {
  border: 1px dashed transparent;
  border-radius: 10px;
  position: relative;
  transition: border 0.5s ease;
}

.box-body:hover ~ .target__point {
  background: #ccc;
  opacity: 0.2 !important;
}

.box-body {
  height: 60px;
  width: 60px;
  background-color: #1b2948;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 5px;
}

.box-body p {
  text-align: center;
  font-size: 8px;
  color: #fff;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-container img {
  margin-bottom: 5px;
  height: 24px;
  width: 24px;
  color: white;
}

.action-bar {
  /* background: rgb(221, 221, 221); */
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 4px 4px;
}

.action-btn {
  background-color: white;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
}

.del-btn {
  outline: none;
  border: none;
  font-size: 20px;
  color: gray;
  position: absolute;
  top: -10px;
  right: -10px;
  display: inline;
  padding: 0;
  line-height: 1;
  background: transparent;
  cursor: pointer;
}

.dndflow {
  /* flex-direction: column; */
  display: flex;
  position: relative;
  flex-grow: 1;
  /* height: 80% !important; */
}

.dndflow aside::-webkit-scrollbar {
  display: none;
}

.sideBarInner {
  position: relative;
  overflow-y: scroll !important;
  width: 92%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-height: 100%;
  align-items: center;
}

.dndflow .Aside {
  position: relative;
  max-width: 1%;
  width: 20%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: max-width 0.5s ease;
}

.showAside {
  max-width: 20% !important;
}

.input {
}

.showSide {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 800;
  top: 39%;
  right: -7% !important;
  height: 80px;
  width: 15px;
  background-color: #1a192b;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.showSide svg {
  color: white !important;
  height: 40px !important;
  width: 20px !important;
  transform: rotate(180deg);
  transition: all 0.5s ease;
}

.showSide .rotate {
  transform: rotate(360deg) !important;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.react-flow__handle-top {
  top: 50% !important;
  left: 0 !important;
  z-index: 20;
}

.dndflow .dndnode {
  height: 20px;
  padding: 4px;
  border: 1px solid #1a192b;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.input {
  position: relative;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  /* height: 80vh; */
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    /* width: 15%; */
  }
}

.Connection {
  background-color: white !important;
}

.react-flow__renderer {
  z-index: unset !important;
  background-color: white !important;
}

/* page type  */
.backIcon {
  width: 74%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between !important;
  /* gap: 20px; */
}

.backIcon p {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.1em;
  color: #1b2948;
}

.backIcon svg {
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.pageTypeSideBar {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 0%;
  /* position: absolute; */
  width: 100%;
  z-index: 400;

  font-size: 12px;
  transition: max-height 0.5s ease !important;
}

.sideBarInner {
  /* max-height: 100vh; */
  overflow-y: hidden !important;
}

.showpageTypeSideBar {
  max-height: 600px !important;
}

.closeIconTag {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 11px !important;
  letter-spacing: 0.1em !important;
}

.css-15wwp11-MuiTableHead-root {
  font-family: "Avenir" !important;
  font-style: normal !important;
  font-weight: 800 !important;
  font-size: 8px !important;
  line-height: 11px !important;

  letter-spacing: 0.1em !important;

  color: #1b2948 !important;
}

/* buttons */
.closebuttn {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.2em;
  color: #1b2948;
  width: 82px;
  height: 25px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  justify-content: center;
  outline: none;
  border: none;
  cursor: pointer;
}

.addbutton {
  width: 82px;
  height: 25px;
  background: #1b2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.2em;
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiOutlinedInput-root {
  /* font-size: 8px !important; */
}

.MuiInputBase-input {
  /* font-size: 8px !important; */
}

.react-flow__panel.right {
  display: none !important;
}

input[type="checkbox"] {
  cursor: pointer;
  accent-color: #1b2948;
}

.MuiAutocomplete-option {
  font-size: 13px !important;
  font-family: "Avenir" !important;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  font-size: 13px !important;
  font-family: "Avenir" !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: 13px !important;
  font-family: "Avenir" !important;
}

.ActionHandle {
  opacity: 0.001;
  height: 30px !important;
  width: 30px !important;
  top: 50% !important;
  left: 30% !important;
}

input,
select {
  font-family: "Avenir" !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  /* font-size: 10px !important; */
  font-size: 13px !important;
  font-family: "Avenir" !important;
  padding: 0 12px !important;
  font-weight: 550 !important;
}

.MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
  right: 0 !important;
  display: none !important;
}

@media screen and (min-width: 1600px) {
  .react-flow {
    height: 100vh !important;
    /* margin-top: 10px; */
  }
}

@media screen and (min-width: 1650px) {
  .react-flow {
    height: 100vh !important;
    /* margin-top: 10px; */
  }
}

@media screen and (min-width: 1750px) {
  .react-flow {
    height: 100vh !important;
    /* margin-top: 10px; */
  }
}

@media screen and (min-width: 2550px) {
  .react-flow {
    height: 100vh !important;
    /* margin-top: 10px; */
  }
}

@media screen and (min-width: 2650px) {
  .react-flow {
    height: 100vh !important;
    /* margin-top: 10px; */
  }
}

@media screen and (min-width: 1800px) {
  .showSide {
    right: -4% !important;
  }
}

svg {
  cursor: pointer;
}

/* scroll bar */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(224, 224, 224);
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
}

#saved-pages {
  position: absolute !important;
  top: 5.8% !important;
  height: 90% !important;
  max-width: 500px !important;
  min-width: 500px;
  overflow: hidden;
}

.MuiPaginationItem-page {
  background-color: #ccc !important;
  color: black !important;
}

.Mui-selected {
  background-color: #1b2948 !important;
  color: white !important;
}

.BackgounedImage {
  background-size: 100% 100% !important;
}

#mobileView {
  /* container-type: inline-size; */
  /* container-type: inline-size; */
}

@media only screen and (max-width: 600px) {
  #mobileView {
    flex-direction: column !important;
    justify-content: center !important;
    width: 300px !important;
    height: fit-content !important;
  }

  #mobileView div img {
    width: 100% !important;
  }

  #mobileView div span {
    /* width: 100% !important; */
  }
}
@container (max-width: 500px) {
  #mobileView div img {
    height: fit-content !important;
    width: 900px;
  }
}
.MuiSlider-track {
  color: #1b2948 !important;
}
.MuiSlider-thumb {
  color: #1b2948;
}
.MuiSlider-rail {
  color: #1b2948;
}
.MuiSlider-valueLabelOpen {
  background-color: black !important;
}

.menu-items {
  max-height: 500px !important;
  min-width: 200px !important;
}

/* loader */

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after {
  position: absolute;
  top: 0;
  content: "";
}

.jimu-primary-loading:before {
  left: -19.992px;
}

.jimu-primary-loading:after {
  left: 19.992px;
  -webkit-animation-delay: 0.32s !important;
  animation-delay: 0.32s !important;
}

.jimu-primary-loading:before,
.jimu-primary-loading:after,
.jimu-primary-loading {
  background: #1a192b;
  -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  animation: loading-keys-app-loading 0.8s infinite ease-in-out;
  width: 13.6px;
  height: 32px;
}

.jimu-primary-loading {
  text-indent: -9999em;
  margin: auto;
  position: absolute;
  right: calc(50% - 6.8px);
  top: calc(50% - 16px);
  -webkit-animation-delay: 0.16s !important;
  animation-delay: 0.16s !important;
}

@-webkit-keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #1a192b;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #1a192b;
    height: 40px;
  }
}

@keyframes loading-keys-app-loading {
  0%,
  80%,
  100% {
    opacity: 0.75;
    box-shadow: 0 0 #1a192b;
    height: 32px;
  }

  40% {
    opacity: 1;
    box-shadow: 0 -8px #1a192b;
    height: 40px;
  }
}

.no-drop-cursor {
  cursor: no-drop !important;
}

.chargebee{
  top: 0;
  position: fixed;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  background-color: #FFFFFF;
  transition: all .3s ease;
  transform: scale(0);
  opacity: 0;
}
.ex1-wrap{
 
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  min-height: 100vh;
}
.ex1-fieldset {
  width: 500px;
}
.ex1-field{
  display: flex;
  flex-direction: column;
 
  margin-top: 10px;
}
.ex1-field input {
  /* border: 1px solid black; */
  padding: 10px 10px 10px 10px;
  outline: none;
  box-shadow: 2px 3px 4px 2px #ccc;
  border-radius: 30px;
}
.ex1-input{
  /* border: 1px solid black; */
  padding: 10px 10px 10px 10px;
  outline: none;
  box-shadow: 2px 3px 4px 2px #ccc;
  border-radius: 30px;
}
.ex1-field label {
  font-size: 20px;
  font-family: 'Avenir';
  margin-left: 10px;
}
.chargbeeSubmit {
  border:  1px solid #1a192b;
  width: 150px;
  margin-top: 10px;
  background-color: #1a192b;
  color: white;
  transition: all .3s ease;
}
.chargbeeSubmit:hover{
  background-color: #FFFFFF;
  color: #1a192b;
  font-family: 'Avenir';
}
.openChargebee{
  transform: scale(1);
  opacity: 1;
}

.Toastify__toast-container--top-center
{
  z-index: 999999 !important;
}
.date_input{
  border: 1px solid #000000;
    border-radius: 5px;
    /* height: 26px; */
    padding: 4px; 
    width: 162px;
}

.component-selected{
  border: 1px dashed blue !important;
}