.PageSideBar {
    z-index: 190000;
   position: fixed;
   left: 1px;
    width: 100px;
    display: flex;
    align-items: center;
    height: 73vh;
   
 
}

.PageSideBar .PageSideBar__inner {
    gap: 1.2em;
    z-index: 100000;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:center;
    height: 100%;
    background: #1B2948;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 40px;
}
.PageSideBar__inner svg {
    font-size: 30px;
}

@media screen and (min-width:1800px) {
    .PageSideBar{
        height: 75vh;
    }
}

@media screen and (min-width:1900px) {
    .PageSideBar__inner{
        gap: 2.3em !important;
    }
}
@media screen and (min-width:2000px) {
    .PageSideBar__inner{
        gap: 2.7em !important;
    }
}
@media screen and (min-width:2100px) {
    .PageSideBar__inner{
        gap: 3em !important;
    }
}
@media screen and (min-width:2200px) {
    .PageSideBar__inner{
        gap: 3.4em !important;
    }
}
@media screen and (min-width:2300px) {
    .PageSideBar__inner{
        gap: 3.8em !important;
    }
}
@media screen and (min-width:2400px) {
    .PageSideBar__inner{
        gap: 4.2em !important;
    }
}
@media screen and (min-width:2500px) {
    .PageSideBar__inner{
        gap: 4.5em !important;
    }
}