.Funnels {
  width: 1064px;
  height: 742px;
  background: rgba(94, 108, 184, 0.1);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px 0px 0px 0px;
  padding: 20px 60px;
  height:100%

}
.FunnelsHead {
  font-family: "Avenir";
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #000000;
}
.Button button {
  cursor: pointer;
  background: #1b2948;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #fff;
  width: 228px;
  height: 37px;
  text-align: center;
  margin-right: 25px;
  font-family: "Avenir";
  font-style: normal;
  font-size: 13px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.1em;
}
.FunnelsCreate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FunnelsSearchDrop {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  /* padding: 6px; */
  width: 620px;
  height: 41px;
  margin-left: 150px;
  color: #000;
}

.FunnelsSearchDrop input {
  width: 100%;
  padding-left: 10px;
  outline: none;
  border: none;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #000000;
}
.FunnelsSearchDrop .inputField {
  width: 100%;
  /* padding-left: 10px; */
  outline: none;
  border: none;
  font-family: "Avenir";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #000000;
}
.inputField {
  width: 100%;
}
.subcontent {
  display: flex;
  margin-bottom: 20px;
}
.subcontent label {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  padding-top: 13px;
  margin-left: 10px;
  width: 200px;

}
.subcontent span {
  padding-top: 13px;
}
.subcontent input {
  font-family: Avenir;
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  width: 227px;
  height: 41px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-left: 142px;
  padding:10px;
}
.content {
  margin-top: 80px;
}
