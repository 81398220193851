.navbar {
    position: relative;
    z-index: 900;
    background-color: white;
    height: 71px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.logo {
    margin-left: 25px;
    width: 31px;
    height: 23px;
    cursor: pointer;
}

.navbarRight {
    margin-right: 23px;
    align-items: center;
    display: flex;
    gap: 20px;
}

.navbarRight svg {
    cursor: pointer;
    width: 27px;
    height: 27px;
}

.navbarRight button {
    cursor: pointer;
    color: #fff;
    background: #1B2948;
    border-radius: 10px;
    width: 122px;
    height: 31px;
}

.navbarRight button:hover {
    background: #1e2e51;
}

.navbarRight img {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 10px;
}


/* second navbar */
.boxshadowNav {

    height: 56px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 10px 10px;
}
.searchInput{
   outline: none;
   border: none;
width: 216px;
height: 20px;
font-family: 'Avenir';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 18px;
letter-spacing: 0.1em;
color: #000000;

}