.summaryModel {
    z-index: 800;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    z-index: 900;
    transform: scale(1);
    opacity: 1;
}
.summaryBody::-webkit-scrollbar{
    display: none;
}
.summaryBody {
    overflow-y: auto;
    max-height: 600px;
    width: 584px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.modelHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}

.EditProductInputField p {
    margin-top: 5px;
}

.EditProductInputField2 {
    gap: 10px;
    margin-top: 20px !important;
    display: flex;
    align-items: center;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
}
.EditProductInputField3 {
    flex-direction: column;
    gap: 10px;
    margin-top: 20px !important;
    display: flex;
   justify-content: center;
    padding-left: 10px;
    padding: 12px 10px;
    width: 98%;
    height: auto;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
}
.EditProductInputField2 p {
    font-weight: bold;
}
.isSelected{
    display: flex;
    align-items: center;
    gap: 10px;
}
.EditProductInputField input {
    margin-top: 5px;
    padding-left: 10px;
    width: 100%;
    height: 40px;
    background: #FFFFFF;
    border-radius: 10px;
    outline: none;
    border: none;
}

.infoStyle {
    display: flex;
    align-items: center;
    gap: 5px;
}

.infoStyle svg {
    cursor: pointer !important;
}
.trailTabs{
    display: flex;
    align-items: center;
    gap: 30px;
    /* justify-content: space-between; */
}
.activeFree{
    background-color: #1B2948 !important;
    color: white !important;
}

.trialAmount{
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #1B2948;
    height: 40px;
    border-radius: 10px;
    overflow: hidden;
}
.trialAmount input{
    margin-top: unset !important;
    height: 100%;
}