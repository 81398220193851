.summaryModel {
    z-index: 800;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .5s ease;
    transform: scale(0);
    opacity: 0;
}

.showSummryModel {
    z-index: 900;
    transform: scale(1);
    opacity: 1;
}

.summaryBody::-webkit-scrollbar {
    display: none;
}

.summaryBody {
    overflow-y: auto;
    max-height: 600px;
    width: 584px;
    height: auto;
    padding: 20px;
    background: #EFF0F8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.summaryBody h1 {
    text-align: center;
    margin-bottom: 20px;
}

.modelHead {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modelHead p {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1em;

    color: #1B2948;
}

.modelHead svg {
    font-size: 25px;
    font-weight: 800;
    cursor: pointer;
}

.contentBody {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.addAssets {

    width: 53%;
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.2em;
    color: #1B2948;
    height: 35px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    justify-content: center;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .3s ease;
    margin-bottom: 20px;
}

.addAssets:hover {
    background-color: #eee;
}

.allCheck {
    border-radius: 10px;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding: 10px;
    width: 50%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.checkAllInner {
   
}