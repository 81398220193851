.ChatGpt {
    border-radius: "10px";
    padding: 50px 0px;
    z-index: 190000;
    top: 15%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    width: 92%;
    height: 85vh;
    transition: all .3s ease;
    transform: scale(0);
    opacity: 0;
}

.propmts {
    padding-bottom: 12px;
    background-color: #EFF0F8;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;

}

.propmts button {
    padding: 10px;
    width: auto !important;
    background-color: #dfe3fc;
    transition: all .2s ease;
}

.propmts button:hover {
    background-color: #EFF0F8;
}

.ChatGptHeading {

    /* position: relative; */
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #EFF0F8;
}

.closeChat {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #EFF0F8;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 2%;
    top: 10%;
    padding: 10px;
}

.ChatGptHeading h1 {
    font-size: 25px;
    text-transform: uppercase !important;
}

.reGenerateResponce {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.reGenerateResponce button {
    width: auto !important;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    outline: .5px solid gray;
    background-color: #dfe3fc;
    margin-bottom: 10px;
    transition: all .2s ease;

}

.reGenerateResponce button:hover {
    background-color: #EFF0F8;
}

.typingEffect p {
    animation: typing 3s steps(40, end);
    /* overflow: hidden; */
    /* border-right: 2px solid; */
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.showChatGpt {
    transform: scale(1);
    opacity: 1;
}

.chatGPTBody {
    /* padding-top: 10px; */
    background: #EFF0F8;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.copy {
    transition: all .3s ease;
    opacity: 0;
}

.gptResponce:hover .copy {
    opacity: 1 !important;
}


.ChatGptModelMain{
    width: 700px;
    position: relative;
    background-color: #EFF0F8;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.25);
    
}

/* .arrow2{
    position: absolute;
    height: 20px;
    width: 20px;
    background-color: white;
    bottom: -15px;
    left: 0;
    border-left: 10px solid red;
    border-bottom: 10px solid red;
} */