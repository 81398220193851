.pageDrawer {
    overflow-y: scroll;
    max-width: 0;
    /* height: 100vh; */
    max-height: 100%;
    position: absolute;
    display: flex;
    top: 0%;
    /* padding-top: 100px; */
    align-items: center;
    /* justify-content: space-around; */
    transition: max-width 1s ease;
    /* overflow: hidden; */
    z-index: 850;
    background: white;
    /* background: rgba(94, 108, 184, 0.1); */
    /* Drop Shadow */
    left: 15.6%;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.closePageDarwer {
    position: absolute;
    top: 52%;
    right: 0%;
    cursor: pointer;

}

.pageDrawer__body__inner {
gap: 5px;
    /* max-height: 75%; */
    overflow-x: scroll !important;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
    position: relative;
}

.pageDrawer__body {
    display: flex;
    /* align-items: center; */
    justify-content: center;
    /* margin-top: 100px; */
    min-height: 100vh;
    /* padding-bottom: 100px; */
    /* padding-top: 100px; */
    width: 250px;
    /* margin-left: 49%; */

}

.opaneDawer {

    max-width: 100% !important;
}

.newPages {

    border-radius: 10px !important;
    position: relative;
    width: 200px;
    height: 100px;
    background-color: #1B2948;
    /* overflow: hidden; */
    margin-top: 5px;
    color: white;
}

.trafficContainer2 {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.newPages img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}
.newPages p {
    border-radius: 10px;
    transition: all .5s ease;
    /* opacity: 0; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0%;
    left: 0%;
    color: white;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.25);
}
/* .newPages:hover +.newPages p{
    opacity: 1;
} */