.sidebarr_inner {
    position: relative;
    overflow-y: scroll;
    width: 90%;
    display: flex;
    flex-direction: column;
    /* align-items: center;     */
    justify-content: space-between;
padding-bottom: 50px;
/* padding-top: 50px; */
    max-height: 88%;
    min-height: 98%;

}

.sidebarr_inner::-webkit-scrollbar {
    display: none;
}

.showSideBar {

    max-width: 15% !important;
}
.showSideBar2 {

    max-width: 30% !important;
    width: 30%!important;
}
.pieChart{
    height: 240px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* display: none !important; */
}
.hideSideBar{
    overflow: hidden !important;
}
.hideSimButton{
    transition: max-width .3s ease;
    max-width: 0 !important;

}
.right__sidebar {
   
    z-index: 800;
    flex-wrap: wrap;
    max-height: 100%;
    justify-content: center;
/* overflow: hidden; */
    align-content: center;
    display: flex;
    width: 15%;
    padding: 0px 8px;
    background:  rgba(94, 108, 184, 0.1);
    transition: max-width .5s ease;
    max-width: 0;
    position: relative;
}

.right_side_open {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1000;
    top: 40.8%;
    left: -7% !important;
    height: 80px;
    width: 15px;
    background-color: #1a192b;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.right_side_open svg {
    color: white !important;
    height: 40px !important;
    width: 20px !important;
    transition: all .5s ease;
}

.rotate {
    transform: rotate(180deg) !important;
}

.butons_right_sidebar {
    margin-top: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 4px 10px;
    width: 90%;
    height: fit-content;
    background: #1B2948;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.simulateButton {
    transition: max-width .3s ease;
    font-family: 'Avenir';
    height: 45px;
    max-width: 100%;
    width: 100%;
    right: 0%;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background: #EB606F;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 5px;
    text-align: center;
    font-weight: 35px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    padding: 10px 0px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.summary {
    font-family: 'Avenir';
    z-index: 400;
    display: flex;
    outline: none;
    border: none !important;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    font-weight: 800;
    font-size: 15px;
    padding: 3px 13px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: left;
    cursor: pointer;
    position: relative;
    transition: all .5s ease;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

@media screen and (min-width:1800px) {
    .right_side_open {
        left: -4% !important;
    }
}